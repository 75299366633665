<template>
 <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
   <div class=" my-border">
     <v-card-title class="py-0 my-0 customlightgray">
       <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{$t('Activities')}}</span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search.searchWord"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn class="ma-auto pa-auto custom-color-accent" depressed @click="addItem">{{$t("newactivity")}}</v-btn>
            </v-col>
            
        </v-row>
      </v-card-title>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{
              $t(formTitle)
            }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container> 
              <v-form  v-on:submit.prevent ref="form"
                v-model="valid"
                lazy-validation >
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="requestConfiguration.LookUpStringE" :rules="[rules.required]" outlined dense :label='$t("Activity in English")' :placeholder='$t("Activity in English")'></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="requestConfiguration.LookUpStringF" :rules="[rules.required]" outlined dense :label='$t("Activity in French")' :placeholder='$t("Activity in French")'></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="requestConfiguration.LookUpKey" :rules="[rules.required]" outlined dense :label='$t("LookUp Key")' :placeholder='$t("LookUp Key")'></v-text-field>
                </v-col>
              </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="close = dialog = false">{{$t('cancel')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="save">{{$t('save')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="600px">
        <v-card>
          <v-card-title>{{$t('delete')}}</v-card-title>
          <v-card-text>{{$t('Are you sure you want to delete this item?')}}</v-card-text>
          <v-card-actions>
            <v-btn class="custom-color-accent-text" text @click="dialogDelete = false">{{$t('Close')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="deleteConfig()">{{$t('delete')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-divider></v-divider>
    <v-data-table :mobile-breakpoint="1081" 
      v-if="configurations"
      :headers="headers"
      :items="getLookup()"
      class="elevation-0 customoffwhite text--secondary pa-0 ma-0"
      :search="search.searchWord"
      :loading="loading" :loading-text="$t('Loading')"
      :footer-props="{
        'items-per-page-text':$t('rowsperpage')
      }"
    >
      <template v-slot:no-data> {{$t('noDataAvailable')}}</template>
      <template v-slot:[`item.LookUpFlag3`]="{item}">
        {{ item.LookUpFlag3 === 'Y' ? $t('Yes') : $t('No')  }}
      </template>
      <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
          <v-row class="ma-0 pa-0 text-right">
            <v-col cols="12" class="ma-0 pa-0">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("edit")}}</span>
              </v-tooltip>
               <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("delete")}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
    </v-data-table>
  </div>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
export default {
  data(){
    return {
      valid: true,
      search:{
        searchword:'',
      },
      rules: {
          required: value => !!value || this.$t('required'),
      },
      editFlag: false,
      deletedItem:null,
      assign: false,
      editedIndex: -1,
      headers: [
        { text: this.$t("Activity in English"), value: "LookUpStringE",maxwidth:"300px"},
        { text: this.$t("Activity in French"), value: "LookUpStringF",maxwidth:"300px"},
        { text: this.$t("Key"), value: "LookUpKey" },
        { text: "", value: "action", sortable: false }
      ],
      dialog: false,
      dialogDelete: false,
    }
  },
  computed: {
    ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration',
      formTitle() {
        return this.editedIndex === -1 ? "newactivity" : "editactivity";
      }
    })
  },
  watch: {
  },
  methods:{
    addItem(){
      this.dialog = true;
      this.editedIndex = 0;
      this.editFlag = false;
      this.emptyConfiguration();
    },
    getLookup(){
      return this.configurations.filter( filter => filter.LookUpName == 'Event' )
    },
    getLookupStatus(){
      return this.configurations.filter( filter => filter.LookUpName === 'ReqStatus' ).map( item => {
        let arr = [];
        arr['text'] = this.$vuetify.lang.current === 'en' ? item.LookUpStringE : item.LookUpStringF;
        arr['value'] = item.LookUpKey;
        arr = Object.assign({},arr );
        return  arr;
      })
    },
    ...mapActions('configuration',['addConfiguration', 'updateConfiguration','setConfigurationItem','setFlagEdit', 'removeConfiguration', 'emptyConfiguration']),
    save(){
      this.requestConfiguration.LookUpName = 'Event';
      if(this.editFlag){
        if(this.$refs.form.validate()){
          this.updateConfiguration(this.requestConfiguration).then(()=>{
              let color="dark";
              let alert="itemUpdatedAlert";
              this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
            })
          this.editFlag = false;
        }
        else
          return
      }else{
         if(this.$refs.form.validate()){
          this.addConfiguration(this.requestConfiguration).then(()=>{
            let color="success";
            let alert="itemAddedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
          })
        }
        else
          return
        
      }
      this.dialog = false;
    },
    editItem(item) {
      this.editedIndex=0;
      this.dialog = true;
      this.editFlag = true;
      this.setConfigurationItem(item);
    },
    deleteItem(item) {
      this.deletedItem = item;
      this.dialogDelete = true;
    },
    async deleteConfig(){
      this.removeConfiguration( this.deletedItem.LookUpTableId ).then(()=>{
      this.dialogDelete = false;
        let color="dark";
        let alert="itemRemovedAlert";
        this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
      })
    },
    close() {
      this.assign = false;
      this.loading = false;
    }
  }

}
</script>

<style scoped>

</style>